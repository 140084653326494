<template>
    <div class="container" id="zsxq">
        <div class="aside" >
           <side-bar ref="sideBar" class="sidebar-container" />
        </div>

        <section class="mainContent">
            <div class="mainContainer" v-if="!isSearch">
                <div class="planet-mainer">
                    <img id="starImg" src="../../assets/image/pl.gif" />
                    <!-- <div class="solar-system-container">
                        <SolarSystem class="solar-system-content"></SolarSystem>
                    </div> -->
                    
                    <div class="search-box-box">
                        <div class="search-box search-box_f mtb40">
                            <div class="searchInput">
                                <el-input v-model="searchTxt" @keyup.enter.native="handleChatIptEnter" placeholder="请输入关键字..." autofocus></el-input>
                                <!-- <div class="switch_btn" @click="handleChatVoice"><img :src="require('../../assets/icon/voice-' + (!chatVoice?'off.png':'on.png'))"/></div> -->
                            </div>
                            <el-button type="primary" class="search-btn" @click="handleSearch">探索</el-button>
                        </div>
                        <div class="search-text">
                            <!-- <div>利用海量体育运动健康语料，进行知识体系构建</div> -->
                            <!-- <p>• 基于实体嵌入的向量化编码技术  •  基于余弦相似的深度度量学习技术  • 基于对抗网络的无监督图谱生成技术</p> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="mainContainer" v-else>
                <div class="main-plan-box">
                    <div class="main-planet-left">
                        <div class="search-box">
                            <div class="searchInput">
                                <el-input v-model="searchTxt" @keyup.enter.native="handleChatIptEnter"></el-input>
                                <!-- <div class="switch_btn" @click="handleChatVoice"><img :src="require('../../assets/icon/voice-' + (!chatVoice?'off.png':'on.png'))"/></div> -->
                            </div>
                            <el-button type="primary" class="search-btn" @click="handleSearch">探索</el-button>
                        </div>

                        <div class="plannet-box">
                            <div class="main-planet-right" v-loading="isLoding">
                                <div class="planet-graph-black" id="graph2d">
                                    <echart-graph :data="planetGraphData" :categories="planetGraphCate" :links="planetGraphLinks" ref="planetGraph"/>
                                </div>
                            </div>
                            <div v-if="isLoding || finishLoding">
                                <el-button
                                        v-show="isLoding"
                                        type="text"
                                        style="display: flex; margin: 0 auto; color: #000"
                                        ><i class="el-icon-loading"></i></el-button>
                                <el-button
                                        type="text"
                                        v-show="finishLoding"
                                        style="display: flex; margin: 0 auto; color: #999"
                                        >已经没有更多啦~</el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <el-dialog
                :close-on-click-modal="false"
                :visible.sync="dialogPdfVisible"
                title=""
                width="60%"
                custom-class="pdf-dialog"
                center
                >
                <div class="dialog-body">
                    <iframe ref="troubleIframe" :src="iframeUrl" width="100%" height="820" frameborder="0"></iframe>
                </div>
            </el-dialog>
        </section>

        <set-dialog :dialogSetVisible="dialogSetVisible"></set-dialog>
        <info-dialog :dialogSysVisible="dialogSysVisible"></info-dialog>
    </div>
</template>

<script>
// import '../../assets/img/styles.css'
// import '../../assets/js/prefixfree.min.js'
// import '../../assets/js/scripts.min.js'
import SideBar from '../../components/Sidebar/index.vue'
// import SolarSystem from '../../components/SolarSystem/index.vue'
import EchartGraph from '@/components/EchartGraph/EchartGraph.vue'
import SetDialog from '../../components/Sidebar/SetDialog.vue';
import { planetList } from '@/api/palnet'
import InfoDialog from '../../components/Sidebar/InfoDialog.vue';
export default {
  name:"Planet",
  components: {
    // SolarSystem,
      SideBar,
      SetDialog,
      EchartGraph,
    InfoDialog
  },
  data() {
    return {
        dialogSetVisible: false,
        isSearch: false,
        searchTxt: '',
        currentPage: 0,
        documentNum: 0,
        planetImg: require('../../assets/image/planet.png'),
        searchVal: '',
				showing: 'summary',
        inversionVal: '0',
        chatVoice: false, //聊天语音开关
        tabPosition: '2D',
        planetList: [],
        planetGraphData: [],
        planetGraphCate: [],
        planetGraphLinks: [],
        planetGraphDataNew: [],
        planetGraphCateNew: [],
        planetGraphLinksNew: [],
        timer: null,
        isLoding: false, // 加载中，loading图标
        finishLoding: false, // 加载完成，显示已经没有更多了
        data3d: {"dims": [], "docs":[]},//3D数据
        docCount: 0,
        dialogPdfVisible: false,
        dialogSysVisible: false,
        iframeUrl: ""
    }
  },
  mounted() {    
    this.$refs.sideBar.handleMenuClick(4);
  },
  methods: {
      queryPlanetList() {
		this.isLoding = true
        // planetList({node_name: this.searchTxt, steps: 7}).then(res => {
        planetList({keyword: this.searchTxt}).then(res => {
        this.isLoding = false
        if(this.currentPage==3){
            this.finishLoding = true
        }
        if(res) {
            this.planetGraphData = res.nodes
            this.planetGraphCate = [{"name": "类目"}, {"name": "术语"}] // res.categories
            this.planetGraphLinks = res.relations
            this.data3d.dims = res.dims
            this.data3d.docs = [{"title": this.searchTxt}]
            this.data3d.docs = this.data3d.docs.concat(res.sources)
            console.log("rock_test planet ....", res)
            this.$nextTick(() => {
                console.log("rock_test planet .... nextTick ")
                this.$refs.planetGraph.handleInit()
            })
        }
        }).catch(err => {this.isLoding = false})
      },
      handleSearch() {
          if(this.searchTxt!=='') {
            if(!this.isSearch){
                this.isSearch = true
            }
            this.currentPage = 1
            this.isLoding = false
            this.finishLoding = false
            this.queryPlanetList()
          } else {
              this.isSearch = false
          }
      },
      handleChatVoice() {
          this.chatVoice = !this.chatVoice
      },
      handleChatIptEnter(event){ //手写回车
        if (event.keyCode == "13") {
            event.preventDefault() // 阻止浏览器默认换行操作
            this.handleSearch()
            return false
        }
     },
  }
}
</script>

<style scoped>
.main-plan-box {
    display: flex;
    height: 100%;
}
.main-planet-left {
    padding: 20px;
    width: 100%;
}
.main-planet-left .search-box {
    width: 100%;
}
.plannet-box .plannet-top {
    border-bottom: 1px solid #DCDFE6;
    display: flex;
    justify-content: space-between;
    height: 35px;
    line-height: 32px;
		margin: 5px;
}
.plannet-top .planet-title {
    color: #333;
    font-size: 14px;
}
.plannet-top .planet-top-right .planet-filter {
    background-image: url(../../assets/icon/icon_filter.png);
    background-repeat: no-repeat;
    background-size: 14px;
    width: 14px;
    height: 14px;
    display: inline-block;
    margin: 0 8px;
    cursor: pointer;
}
.plannet-top .planet-top-right .planet-sort {
    background-image: url(../../assets/icon/icon_sort.png);
    background-repeat: no-repeat;
    background-size: 14px;
    width: 14px;
    height: 14px;
    display: inline-block;
    margin: 0 8px;
    cursor: pointer;
}

.solar-system-container {
    /* background-image: url(../../assets/image/bg-stars.png); */
    /* background-repeat: no-repeat; */
    width: 100%;
    height: calc(100vh - 40px);
    /* width: calc(100% - 40px); */
    background-color: #08090A;
    /* background-color: #CACBDE; */
}

/* .solar-system-content {
    width: 100%;
    height: 60%;
} */

.planet-list .planet-list-item {
    padding: 5px 5px 7px;
    margin-top: 10px;
    border-radius: 8px;
    cursor: pointer;
}
.planet-list .planet-list-item:hover {
    background-color: rgba(77, 130, 255, 0.1);
}
.planet-list .planet-list-item.selected {
    background-color: rgba(77, 130, 255, 0.1);
}

.planet-list-item .planet-list-title-box {
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
}
.planet-list-title-box .planet-list-title {
    display: flex;
    font-size: 18px;
    align-items: center;
    color: #4d82ff;
    width: 86%;
}
.planet-list-title-box .planet-list-title img {
    margin-right: 5px;
    height: 20px;
}
.planet-list-title-box .planet-list-title span {
    display: inline-block;
    width: 100%;
    height: 26px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.planet-list-title-box .planet-list-score {
    font-size: 14px;
    color: #999;
}
.panet-list-txt .active {
    background-color: #c5d7ff !important;
}
.planet-list-item .planet-list-content {
    display: flex;
    font-size: 14px;
    align-items: center;
}
.planet-list-content .planet-list-img {
    margin-right: 8px;
}
.planet-list-content .planet-list-img img {
    width: 78px;
    height: 48px;
}
.planet-list-content .panet-list-txt {
    line-height: 18px;
    color: #333;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-height: 53px;
}
/deep/.planet-list-item .active {
    background-color: #c3d4ff;
}
.planet-list-score span {
    color: #32b16c;
}
.planet-mainer {
    text-align: center;
    padding: 0px 0 0;
    border-radius: 20px;
    overflow: hidden;
    padding-top: 50px;
}
.planet-mainer img {
    width: 70%;
} 
.planet-mainer .search-text {
    padding: 0 30px;
    font-size: 12px;
    color: #000;
}
.search-text p {
    font-size: 14px;
    color: #768687;
}
.search-box {
    width: 500px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.search-box-box{
    position: fixed;
    top: 68%;
    left: 45%;
}
/* /deep/.search-box .el-input--medium {
    font-size: 16px;
} */
/deep/.search-box .el-input--medium .el-input__inner {
    height: 42px;
    line-height: 42px;
    background-color: rgba(77, 130, 255, 0.01);
    border-radius: 5px 0 0 5px;
    padding-right: 40px;
}
/deep/.search-box_f .el-input--medium .el-input__inner {
    color: #333;
}
/deep/.search-box .el-button--medium {
    font-size: 15px;
    background-image: url(../../assets/icon/icon_search.png);
    background-repeat: no-repeat;
    background-size: 18px;
    background-position: 8px 11px;
    padding-left: 32px;
    border-radius: 0 5px 5px 0;
}
.searchInput {
    width: 100%;
    position: relative;
}
.search-btn{
    background-color: #c90e17;
    border-color: #c90e17;
}
/deep/.el-input__inner:focus {
    border-color: #c90e17;
}
.searchInput .switch_btn {
    position: absolute;
    right: 0;
    width: 42px;
    height: 42px;
}
.searchInput .switch_btn img {
    width: 100%;
    height: 42px;
    margin: 0;
}
.mtb40 {
    margin: 40px auto;
}

.main-planet-right {
    width: 100%;
    margin: 20px 20px 20px 0;
    height: calc(100% - 20px);
    /* background-color: #f3f3fb; */
    /* border: 1px solid #bdbecf; */
    border-radius: 10px;
}
.main-planet-right .planet-right-top {
    display: flex;
    justify-content: space-between;
    height: 36px;
    align-items: center;
    background-color: #ebebf3;
    border-bottom: 1px solid #bdbecf;
    border-radius: 10px 10px 0 0;
}
.planet-right-top .planet-right-title {
    padding-left: 10px;
    color: #333;
}
.planet-right-top .planet-right-box {
    padding-right: 8px;
    display: flex;
}
/deep/.planet-right-box .el-radio-button--medium .el-radio-button__inner {
    padding: 5px 10px;
    border-color: transparent;
    background-color: #bdbecf;
}
/deep/.planet-right-box .el-radio-button__orig-radio:checked+.el-radio-button__inner {
    background-color: #4d82ff;
}
.plannet-box {
    height: calc(100% - 35px);
    overflow: hidden;
}
.planet-list {
    max-height: calc(100% - 35px);
    overflow: auto;
}
.planet-document {
    display: flex;
    align-items: center;
}
.planet-document .planet-t {
    font-size: 14px;
    color: #333;
}
/deep/.planet-document .el-progress {
    width: 80px !important;
    margin: 0 5px !important;
}
/deep/.planet-document .el-progress .el-progress-bar {
    padding-right: 0 !important; 
    margin-right: 0 !important;
}
/deep/.planet-document .el-progress .el-progress__text {
    display: none !important;
}
/deep/.planet-document .el-progress-bar__outer {
    height: 4px !important;
    background-color: #bdbecf !important;
}
/deep/.planet-document .el-progress-bar__inner {
    background-color: #c90e17 !important;
}
.planet-input {
    width: 32px;
    margin-right: 8px;
}
/deep/.planet-input .el-input--mini .el-input__inner {
    padding: 0;
    text-align: center;
    color:#000;
}
video::-webkit-media-controls-timeline {
  display: none;
}

/deep/.planet-top-right .el-radio-group {
  padding-left: 10px;
}
/deep/.planet-top-right .el-radio-button--medium .el-radio-button__inner {
  background-color: #f0f4fb;
  width: 24px;
  height: 24px;
  padding: 0;
  border: 1px solid #d2d5e8;
  background-repeat: no-repeat;
  background-position: center;
  font-size: 0;
}
/deep/.planet-top-right .el-radio-group .el-radio-button:first-child .el-radio-button__inner {
  background-image: url(../../assets/icon/icon_wd.png);
}
/deep/.planet-top-right .el-radio-group .el-radio-button:last-child .el-radio-button__inner {
  background-image: url(../../assets/icon/icon_list.png);
}
/* /deep/.planet-top-right .el-radio-group .el-radio-button:last-child .el-radio-button__inner.list-inner {
  background-image: url(../../assets/icon/icon_list.png);
} */
/deep/.planet-top-right .el-radio-group .el-radio-button__orig-radio:checked+.el-radio-button__inner {
  background-color: #4d82ff;
  border-color: #4d82ff;
}
/deep/.planet-top-right .el-radio-group  .el-radio-button:first-child .el-radio-button__orig-radio:checked+.el-radio-button__inner {
  background-image: url(../../assets/icon/icon_wd_active.png);
}
/deep/.planet-top-right .el-radio-group  .el-radio-button:last-child .el-radio-button__orig-radio:checked+.el-radio-button__inner {
  background-image: url(../../assets/icon/icon_list_active.png);
}
/deep/.planet-top-right .el-radio-group  .el-radio-button:last-child .el-radio-button__orig-radio:checked+.el-radio-button__inner.list-inner {
  background-image: url(../../assets/icon/icon_list_active.png);
}
.planet-graph-black{
	display:black
}
.planet-graph-none{
	display:none
}
/deep/.pdf-dialog .el-dialog__header {
    height: 0px;
    padding: 0;
}
/deep/.pdf-dialog {
    width: calc(100% - 280px) !important;
    height: calc(100% - 40px);
    margin: 20px 0 0 260px !important;
    border-radius: 20px;
}
/deep/.pdf-dialog .el-dialog__headerbtn {
    top: 4px !important;
    right: 12px;
}
/deep/ #universe{
    padding-top: 10% !important;
}

#starImg{
    mix-blend-mode: multiply;
    /* 向右浮动 */
    transform: translateX(62px);
}
</style>