<template>
  <div>
    <div id="graph-main"></div>
    <div
      :class="`drawer${dialogVisible ? ' show-drawer' : ''}`"
      @click.self="dialogVisible = false"
    >
      <div class="drawer-right-bg">
        <div class="drawer-right">
          <div class="drawer-title" ref="drawerTitle">
            <div class="drawer-scroll-title" style="text-align: center;font-size: 18px;padding: 0 30px;">{{ dialogTitle }}</div>
            <i
              class="close-btn el-icon-close"
              @click="dialogVisible = false"
            ></i>
          </div>

          <div class="drawer-scroll2" ref="drawerScroll2Ref" :style="{height: scrollHeight}">
            <!-- <span class="drawer-scroll-title">{{ dialogTitle }}</span> -->
            <div
              class="drawer-content"
              style="padding-top: 20px"
              v-html="dialogContent"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import * as echarts from "echarts";
import { getNodeInfo } from "@/api/graph";

let that = null;
export default {
  name: "EchartGraph",
  props: {
    //title: {type: String, required: true}, //画布高
    data: { type: Array, required: true }, //参数
    categories: { type: Array, required: true }, //参数
    links: { type: Array, required: true }, //参数
  },
  data() {
    return {
      title: "",
      // data: [],
      // categories: [],

      dialogVisible: false,
      dialogTitle: '',
      dialogContent: '',
      scrollHeight: "calc(100% - 40px)",
    };
  },
  mounted() {
    that = this;
  },
  methods: {
    async clickTreeNode(node) {
      this.$refs.drawerScroll2Ref.scrollTop = 0;
      this.dialogVisible = true;
      this.dialogTitle = node.name_cn;
      this.dialogContent = '';
      const nodeText = await getNodeInfo({ id: node.nid });
      if(nodeText){
        this.dialogContent = nodeText.contentHtml;
      }else {
        this.dialogContent = '暂无'
      }
      this.$nextTick(() => {
        this.scrollHeight = `calc(100% - ${this.$refs.drawerTitle.clientHeight}px)`;
      });
    },
    getChartZoom(length) {
      const l = 15;
      const s = 0.25;
      let zoom = 1.2;
      if (length > l) {
        zoom = 1.2 - parseInt(length / l) * s;
      }
      return zoom < 0.2 ? 0.2 : zoom;
    },
    handleInit() {
      //初始化
      console.log(
        "handleInit enter {}/{}",
        this.data.length,
        this.links.length
      );

      let option = {
        textStyle: {
          fontSize: 14,
        },
        backgroundColor: "",
        // 图的标题
        title: {
          text: that.title,
          top: 30,
          textStyle: {
            fontSize: 18,
          },
          textAlign: "left",
          left: 30,
        },
        // 提示框的配置
        tooltip: {
          formatter: function (x) {
            if (x.data.name_cn == x.data.des) {
              return (
                "<div style='max-width:600px; white-space: normal;word-break: break-word;line-height:25px; font-weight: bold;'>" +
                x.data.des +
                "</div>"
              );
            } else if (x.data.des == null) {
              return (
                "<div style='max-width:600px; white-space: normal;word-break: break-word;line-height:25px; font-weight: bold;'>" +
                x.data.name_cn +
                "</div>"
              );
            } else if (x.data.name_cn != null && x.data.des != null) {
              return (
                "<div style='max-width:600px; white-space: normal;word-break: break-word;'><div style='line-height:25px; font-weight: bold;'>" +
                x.data.name_cn +
                "</div>" +
                x.data.des +
                "</div>"
              );
            } else {
              return "";
            }
          },
        },
        // 工具箱
        toolbox: {
          // 显示工具箱
          show: false,
          feature: {
            mark: {
              show: true,
            },
            // 还原
            restore: {
              show: false,
            },
            // 保存为图片
            saveAsImage: {
              show: false,
            },
          },
          top: 20,
          right: 30,
          confine: false,
        },
        // 图例
        legend: [
          {
            // selectedMode: 'single',
            bottom: 30,
            right: 30,
            orient: "vertical",
            data: that.categories.map(function (a) {
              return a.name;
            }),
          },
        ],
        // 关系图
        series: [
          {
            type: "graph", // 类型:关系图
            layout: "force", //图的布局，类型为力导图
            //symbol: 'rect', //形状 长条型
            symbolSize: 55, // 调整节点的大小
            roam: true, // 是否开启鼠标缩放和平移漫游。默认不开启。如果只想要开启缩放或者平移,可以设置成 'scale' 或者 'move'。设置成 true 为都开启
            // zoom: 0.18, // 数据多的情况下控制显示区域大小
            zoom: this.getChartZoom(this.data.length), // 数据多的情况下控制显示区域大小
            edgeSymbol: ["circle", "arrow"], // 边两端的标记类型
            edgeSymbolSize: [2, 10], // 节点标记的大小，可以设置成诸如 10 这样单一的数字，也可以用数组分开表示宽和高，例如 [20, 10] 表示标记宽为20，高为10。
            left: "left",
            force: {
              repulsion: 2500, // 节点之间的斥力因子
              edgeLength: [10, 50],
            },
            draggable: true, //节点是否可拖拽
            lineStyle: {
              // 关系边的公用线条样式
              normal: {
                width: 1,
                color: "#4b565b",
                show: false,
              },
            },
            edgeLabel: {
              normal: {
                show: false,
                formatter: function (x) {
                  return "";
                },
              },
            },
            // 图形上的文本标签，可用于说明图形的一些数据信息，比如值，名称等
            label: {
              normal: {
                show: true,
                textStyle: {},
                formatter: function (x) {
                  return x.data.name_cn.slice(0, 6);
                },
              },
            },
            // 数据
            data: that.data,
            links: that.links,
            categories: that.categories,
          },
        ],
        color: ["#5599FF", "#32e191", "#f39800"],
      };

      let dom = document.getElementById("graph-main");
      dom.style.width = window.innerWidth - 320 + "px";
      dom.style.height = window.innerHeight * 0.85 + "px";
      console.log("handleInit end");
      this.barGraph = echarts.init(dom, { renderer: "svg" });
      this.barGraph.setOption(option, true);

      this.barGraph.on("click", function (param) {
        console.info(param.data.category);
        // if(param.data.category=="文章"){
        //     console.info(param.data.name)
        // }
        // try{
        // that.$parent.handlEchartGraphCallClick(param)
        // that.handleSelectItem(param.data.name, 1)
        // }catch(e){
        //     console.error(`ERROR: ${e.message}`);
        // }

        if (param.data.category === "术语") {
            that.clickTreeNode(param.data);
        }
      });
    },
    handleSelectItem(name, type) {
      // var option = this.barGraph.getOption();
      // var count = 0
      // for(var i=0; i<option.series[0].data.length; i++){
      //     if(option.series[0].data[i]["symbolSize"]){
      //         delete option.series[0].data[i]["symbolSize"]
      //     }
      //     if(option.series[0].data[i]["itemStyle"]){
      //         delete option.series[0].data[i]["itemStyle"]
      //     }
      //     let text = option.series[0].data[i].name
      //     if(type==2){
      //         text = this.$md5(option.series[0].data[i].content)
      //     }
      //     if(count==0 && text==name){
      //         option.series[0].data[i]["symbolSize"] = 80
      //         option.series[0].data[i]["itemStyle"] = {
      //             "normal": {
      //                 "borderWidth": 2,
      //                 "shadowBlur": 10,
      //                 "shadowColor": "#EEEE00",
      //             }
      //         }
      //         count = 1
      //     }
      // }
      // this.barGraph.setOption(option)
    },
  },
};
</script>
<style scoped>
.drawer {
  position: fixed;
  top: 100px;
  right: -100vw;
  width: calc(100vw - 320px);
  height: calc(100vh - 120px);
  transition: all 0.4s;
}

.drawer-right {
  height: 100%;
  width: 100%;
  position: relative;
  background-image: linear-gradient(120deg, #fccb9025 0%, #c90e1725 100%);
  overflow: hidden;
  z-index: 10;
  /* 左侧shadow */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url("~@/assets/image/beijing.png");
    filter: hue-rotate(325deg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    z-index: -1;
    opacity: 0.2;
  }
}

.drawer-right-bg {
  height: 100%;
  width: 700px;
  background: #fff;
  /* background: url("~@/assets/image/beijing.png"); */
  float: right;
}

.drawer-title {
  width: 100%;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.close-btn {
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.show-drawer {
  right: 20px;
}

.drawer-content {
  width: 100%;
  padding: 0 15px;
  padding-bottom: 20px;
  box-sizing: border-box;
}

.drawer-scroll2 {
  width: 100%;
  height: calc(100% - 40px);
  overflow-y: auto;
}
</style>

<style>
.drawer-content table {
  border: 1px solid #ccc;
}
.drawer-content table th,
.drawer-content table td {
  border: 1px solid #ccc;
}
.drawer-content table td {
  padding: 0px 5px;
}
.drawer-content table {
  margin-top: 30px;
  border-collapse: collapse;
  max-width: 100%;
}

.drawer-content th {
  background-color: #f2f2f29e;
  font-weight: 700;
}

.drawer-content th,
.drawer-content td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.drawer-content td ul {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.drawer-content td ul li {
  margin-left: 30px;
}

.drawer-content td ul li:first-child {
  list-style-type: none;
  margin-left: 0;
}

.drawer-content h2 {
  margin-bottom: 5px;
  margin-top: 40px;
  font-weight: 400;
}

.drawer-content h3 {
  margin-bottom: 5px;
}

.drawer-content ul,
.drawer-content ol {
  margin: 5px 0;
}

.drawer-content section + section {
  margin-top: 15px;
}

.drawer-content blockquote {
  overflow: hidden;
  margin: 1em 0;
  padding: 0 32px;
  border-left: 3px solid var(--border-color-subtle, #c8ccd1);
}
</style>