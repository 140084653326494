import request from '@/utils/request'

/**
 * 知识星球
 * @param pram
 */
export const planetList1 = (data) => {
    return request({
      url: '/knowledge/planet',
      method: 'get',
      params: data
    })
}


/**
 * 知识星球
 * @param pram
 */
export const planetList = (data) => {
  return request({
    url: '/planet/search',
    method: 'get',
    params: data
  })
}
